@use '@ingka/variables/style.scss' as variables;

.imageWrapper {
    background: variables.$colour-neutral-1;
    display: flex;
    justify-content: center;
    padding: variables.$space-75;
    height: variables.$space-550;
    flex: 1;
    @include variables.md {
        height: variables.$space-750;
    }
}

.imagesContainer {
    display: flex;
    gap: variables.$space-50;
}
