@use '@ingka/variables/style.scss' as variables;

.wrapper {
    display: flex;
    gap: variables.$space-50;
}

.listInfo {
    flex: 2 1;
}

.listName {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
}
