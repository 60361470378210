@use '@ingka/variables/style.scss' as variables;

.container {
    background: transparent;
    padding: 0;
    margin: 0;
    border: none;
    text-align: left;
}

.wrapper {
    padding: variables.$space-75;
    display: flex;
    flex-direction: column;
    background-color: variables.$colour-neutral-2;
    gap: variables.$space-75;
    &:hover {
        cursor: pointer;
    }
}
